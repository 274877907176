@import '~antd/dist/antd.less';

// theme custmize
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@btn-font-weight: 400;
@btn-primary-bg: #2cbbdb;
@btn-disable-color: #adadad;
@btn-disable-bg: #ddd;
@link-color: #2cbbdb; // link color
@success-color: #39d94c; // success state color
@warning-color: #e0c834; // warning state color
@error-color: #e35c5c; // error state color
// @font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
// @border-radius-base: 2px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
//   0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers
@layout-header-background: #fff;
@layout-body-background: #f6f6f6;

// layout content
.ant-layout {
  min-height: calc(100vh - 64px);
}
.ant-layout-content {
  flex: none;
}

// tag
@tag-font-size: 12px;
.ant-tag {
  border-radius: 6px;
  font-weight: bold;
  padding: 1px 7px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 24px;
  line-height: 1;

  & > .anticon + span {
    width: 100%;
  }
}

// side menu
.side-menu.ant-menu {
  border-right: 0;
  font-weight: bold;
  overflow: hidden;
  .ant-menu-item {
    border-bottom: 1px solid rgba(26, 162, 181, 0.5);
    margin: 0;
    align-items: center;
    display: flex;
    &.ant-menu-item-selected {
      background-color: rgba(0, 0, 0, 0.05);
    }
    &.ant-menu-item-active {
      background-color: rgba(255, 255, 255, 0.22);
    }
  }
}

.ant-layout-sider-zero-width-trigger {
  background: transparent
    linear-gradient(171deg, #37c8d6 0%, #26b4de 51%, #39fef4 100%) 0% 0%
    no-repeat padding-box;
}

// menu
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-item-active-bg: rgba(255, 255, 255, 0.22);
@menu-item-active-border-width: 0;
@menu-inline-toplevel-item-height: 57px;

.ant-dropdown-menu-item {
  padding: 0 12px;
}
.ant-dropdown-menu-item-divider {
  margin: 0;
}

// list
.ant-list-header {
  padding: 0px 44px;
  min-height: 62px;
  align-items: center;
  display: flex;
  background-color: #fafafa;
}
.ant-list-split {
  .ant-list-item {
    border-bottom: 1px solid #e8e8e8;
    a {
      color: #383838;
    }
  }
}

// pagination
@pagination-item-bg-active: #2cbbdb;

.ant-table-pagination.ant-pagination {
  margin: 30px 0;
  padding: 0 44px;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.ant-pagination-item {
  border-radius: 50%;
  min-width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .pagination-text {
    font-size: 15px;
  }

  &.ant-pagination-item-active {
    .pagination-text,
    a {
      color: #fff;
    }
  }
}

// modal
.ant-modal-header {
  text-align: center;
  padding: 55px 24px 0;
  border-bottom: none;
}
.ant-modal-title {
  font-weight: bold;
  letter-spacing: 1.28px;
}
.ant-modal-content {
  border-radius: 4px;
  overflow: hidden;
}

.ant-modal-body {
  padding: 24px 24px 65px;
}

// button
.ant-btn-round.ant-btn-lg {
  height: 49px;
}

// green button
.button-green.ant-btn {
  background-color: #39d94c;
  border: 1px solid #39d94c;
  color: #fff;

  &:hover,
  &:active {
    border: 1px solid #5eea6f;
    background-color: #5eea6f;
  }
}

// input
@input-height-lg: 48px;
@input-bg: #fafafa;
.ant-input {
  border-radius: 4px;
}

// form
.ant-form-item-label {
  & > label {
    width: 100%;

    .notice {
      position: absolute;
      right: 0;
      font-size: 11px;
      color: #c1c1c1;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

// tooltip
.ant-tooltip {
  &.graph-tooltip {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        background-color: #fff;
        color: #707070;
        border-radius: 4px;
        border: 2px solid #2898de;
      }
      .ant-tooltip-arrow {
        z-index: 1;
        overflow: inherit;
        .ant-tooltip-arrow-content {
          width: 10px;
          height: 10px;
          background-color: #fff;
          border-bottom: 2px solid #2898de;
          border-right: 2px solid #2898de;
        }
      }
    }
  }
}

// anchor link
.header-anchor-link.ant-anchor-wrapper {
  background-color: transparent;
  height: 100%;
  overflow: inherit;
  .ant-anchor {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 18px;
      background-color: #e8e8e8;
    }

    .ant-anchor-ink {
      display: none;
      padding: 7px 0;
    }
    .ant-anchor-link {
      height: 100%;
      margin: 0 20px;
      padding: 0;

      .ant-anchor-link-title {
        height: 100%;
        padding: 7px 0;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        overflow: inherit;

        &:hover {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: #2cbbdb;
          }
        }
      }
    }
  }
}
